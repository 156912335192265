<script setup lang="ts">
import icCloseIcon from '@/assets/img/icon/support-worker/ic-close.svg';
import { AppTypography } from '@/components/base';
import type { Availability } from '@/types/Availability';
import dayjs from 'dayjs';

const props = withDefaults(
  defineProps<{
    schedules: Availability[];
  }>(),
  {
    schedules: () => [],
  }
);

const availability = computed((): Availability[] => {
  const schedules = [];

  for (let day = 0; day <= 6; day++) {
    const exist = getSchedule(day);
    schedules.push(
      exist || {
        day: day,
        day_name: mapDayToName(day),
        start: null,
        end: null,
      }
    );
  }

  return schedules;
});

const formatSchedule = (start: string, end: string, format: string = 'hh:mma'): string => {
  return `${dayjs(start, 'hh:mm').format(format)} to ${dayjs(end, 'hh:mm').format(format)}`;
};

const getSchedule = (day: number): Availability | null => {
  return props.schedules?.find((a) => a.day === day);
};

const mapDayToName = (day: number): string => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[day];
};
</script>
<template>
  <div class="schedule-availability">
    <div
      v-for="(schedule, n) in availability.sort((a, b) => a.day - b.day)"
      :key="n"
      :data-testid="`day--${schedule.day_name}`"
      class="d-flex justify-space-between align-center mr-2"
    >
      <div class="d-flex flex-grow-1">
        <AppTypography type="subheadline-2" class="schedule-availability__item mr-2">
          {{ schedule.day_name }}
        </AppTypography>
      </div>
      <template v-if="schedule.start && schedule.end">
        <AppTypography type="headline-1" class="schedule-availability__schedule">
          {{ formatSchedule(schedule?.start || '01-01-2022 22:00:00', schedule?.end || '01-01-2022 22:00:00') }}
        </AppTypography>
      </template>
      <template v-else>
        <v-img :src="icCloseIcon" max-width="12px" height="12px" class="schedule-availability__x" />
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.schedule-availability {
  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  &__item {
    font-weight: 500;
  }

  &__schedule {
    font-family: 'Satoshi';
    font-weight: 400;
  }
}
</style>
